.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 15px;
    margin-right: 150px;
    z-index: 9999999;
    width: 400px;
    float: right;
}
.totalBar {
    /* right: 0; */
    width: 350px;
    height: 45px;
    display: flex;
    align-items: center;
    
    padding: 5px;
    background-color: #cae8ca;
    border: 2px solid #4CAF50;


}

.totalBar-item {
    margin-left: 20px;
    text-align: center;
}

.products {

}
.section {
    margin-top: 20px;
}

td {
    text-align: center;
}

.p-card .p-card-body {
    paddingTop: 0;
    paddingBottom: 0
}
.p-card .p-card-content
 {
    padding: 0
}

.nav-link {
    color: white;
}

.nav-link:hover {
    color: gray
}