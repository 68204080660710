/* .orders {
    margin-left: 5%;
    margin-right: 5%;
} */


td {
    word-wrap: break-word;
}

.header {
    justify-content: space-between;
    margin-top: 3px;
    margin-bottom: 3px;
}