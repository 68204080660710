.parent {}

.div1 {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.div2 {}